<template>
  <div class="content-body">
    <div class="container text-start">
      <div class="row">
        <div class="col-xxl-6">
          <div
            class="promotion d-flex justify-content-between align-items-center"
          >
            <div class="promotion-detail">
              <h3 class="text-white mb-3">Kensho</h3>
              <p>
                Death was final, until it wasn’t. After the first law of nature
                broke, more followed, unraveling nature from its laws and into
                chaos. A few who embrace nature’s essence exist between law and
                chaos. The Kensho.
              </p>
              <a
                href="https://oasis.cash/collection/0x47C388a14712434B14b2a111ce4042B537D69d4a"
                target="_blank"
                class="btn btn-secondary me-3"
                >Buy on Oasis</a
              >
            </div>
          </div>
        </div>
        <div class="col-xxl-6">
          <div class="card top-bid">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <img
                    src="/images/avatar/kenshoX.png"
                    class="img-fluid rounded"
                    alt="..."
                  />
                </div>
                <NftTop @refreshEvent="reloadChildren" ref="nftTop" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Nft @refreshEvent="reloadChildren" ref="nft" :currColl="currColl" />
  </div>
</template>

<script>
import Nft from "@/components/Nft.vue";
import NftTop from "@/components/NftTop.vue";

// Mainnet
import initialStakes from "../bchMainnet/kenshoX-staked.json";
import power from "../bchMainnet/kenshoX-power.json";
import minpower from "../bchMainnet/kensho-minpower.json";

// // Testnet
// import initialStakes from "../bchTestnet/kenshoXTest-staked.json";
// import power from "../bchTestnet/kenshoXTest-power.json";
// import minpower from "../bchMainnet/kensho-minpower.json";

export default {
  name: "App",
  components: { Nft, NftTop },

  data() {
    return {
      currColl: {
        name: "kenshoX",
        fullName: "Kensho Exclusive",
        nftCA: window.address["kenshoX"],
        power: power,
        minpower: minpower,
        initialStakes: initialStakes,
        pictureLink:
          "https://raw.githubusercontent.com/NftClubCash/kensho-exclusive/main/png/",
      },

      nftMarketPlace: window.nftMarketPlace,
    };
  },
  methods: {
    reloadChildren: function () {
      this.$refs.nftTop.load();
      this.$refs.nft.load();
    },
  },
};
</script>
